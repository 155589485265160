import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';  
import './index.css';
import App from './App';
import ComingSoon from './components/ComingSoon';
import reportWebVitals from './reportWebVitals';

const comingSoonVideoUrl = './comingsoon.mp4';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  /*
    In development mode, React’s StrictMode renders a component twice to 
    detect problems with your implementation in order to warn you about these.
    This StrictMode is automatically excluded for applications in production.
  */
  // <React.StrictMode>
  // <BrowserRouter>
    // <App />
    <>
    <ComingSoon videoSrc={comingSoonVideoUrl} />
    </>
    
  // </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
