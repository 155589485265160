import React, { useRef, useEffect } from 'react';
import './ComingSoon.style.css';

const ComingSoon = ({ videoSrc }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    // Auto-play video when component mounts
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error('Video autoplay failed:', error);
      });
    }
  }, []);

  return (
    <div className='coming-soon-page'>
      <video
        ref={videoRef}
        className='background-video'
        autoPlay
        muted
        loop
        crossOrigin='anonymous'
        preload='metadata'
        playsInline
        disablePictureInPicture
      >
        <source src={videoSrc} type='video/mp4' />
        Your browser does not support the video tag.
      </video>

      <div className='content-container'>
        <div className='info-section'>
          <div className='info-wrapper'>
            <p className='info-text'>
              It's not here yet, but I'll let you in on a Secret. It's coming
              really, really soon. So sit tight and check back in on May 31. You
              just might see something that will blow your socks off!
            </p>

            <div className='contact-info'>
              <p>Really excited to get in touch with me?</p>
              <p>
                Send me an email at{' '}
                <a
                  href='mailto:schzhanggx2020@gmail.com'
                  className='email-link'
                >
                  schzhanggx2020@gmail.com
                </a>
              </p>
              <p>
                Or follow me at{' '}
                <a
                  href='https://x.com/igxz'
                  className='email-link'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  @igxz
                </a>{' '}
                on X for updates and news.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
